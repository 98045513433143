import React, { useState } from 'react';
import { SensorCard } from './Cards/SensorCard';
import { InputWithButton } from './inputs/InputWithSubmit';
import { ThreeCanvas } from './Renderer';
import './Subscriber.css';
const ws = new WebSocket(process.env.REACT_APP_API_URL);

//import Pusher from 'pusher-js';
//import io from 'socket.io-client';

//const URL = process.env.REACT_APP_API_URL

export function Subscriber({ title, needsToFetch, setNeedsToFetch}) {
  const [oriX, setOriX] = useState(0)
  const [oriY, setOriY] = useState(0)
  const [oriZ, setOriZ] = useState(0)

  const [offX, setOffX] = useState(0)
  const [offY, setOffY] = useState(0)
  const [offZ, setOffZ] = useState(0)
  const [isPhone, setIsPhone] = useState(true)

  ws.addEventListener('message', (message) => {
    //console.log(data);
    if (message.data && typeof message.data === 'string') {
      try {
        const msg = JSON.parse(message.data);
    
        // Check if all required variables are present in the JSON object
        if (
          'x' in msg && typeof msg.x === 'number' &&
          'y' in msg && typeof msg.y === 'number' &&
          'z' in msg && typeof msg.z === 'number' &&
          'xOff' in msg && typeof msg.xOff === 'number' &&
          'yOff' in msg && typeof msg.yOff === 'number' &&
          'zOff' in msg && typeof msg.zOff === 'number'
        ) {
          const { x, y, z, xOff, yOff, zOff } = msg;
    
          setOriX(x);
          setOriY(y); //z
          setOriZ(z); //y
          setOffX(xOff);
          setOffY(yOff);
          setOffZ(zOff);
        } else {
          console.error('Missing variables in JSON object:', msg);
        }
      } catch (error) {
        console.error('Invalid JSON string:', message.data);
      }
    }
    
  });



  const handleClick = (inputValue) => {
    ws.send(JSON.stringify({
      channel: inputValue,
      event: "listen",
      message: "subscriber joined"
    }));
  };

  const handleSwitchModel = (inputValue) => {
    setIsPhone(!isPhone)
  };


  return (
    <section>
        <div>
        {/* <p>Subscribed to channel: {channel}</p>  */}
           <div className='topSub'>
            <InputWithButton className="channelSub" labelname={"channelsub"} labeltext={"Subscribe to Channel"} onSubmit={handleClick} submitButtonId="subToChannelButton"/>
            <SensorCard className="sensorCard" title="OriFromPhone" x={oriX-offX} y={oriY-offY} z={oriZ-offZ}/>
            <button className='changeButton' onClick={handleSwitchModel}><p>Change Model</p></button>
          </div>
          <ThreeCanvas rotationX={oriX} rotationY={oriY} rotationZ={oriZ} offsetX={offX} offsetY={offY} offsetZ={offZ} isPhone={isPhone}/>
        </div>
        <div className='submits'>
          <InputWithButton labelname={"updateX"} labeltext={"update X"} onSubmit={setOriX} submitButtonId="updateXButton"/>
          <InputWithButton labelname={"updateY"} labeltext={"update Y"} onSubmit={setOriY} submitButtonId="updateYButton"/>
          <InputWithButton labelname={"updateZ"} labeltext={"update Z"} onSubmit={setOriZ} submitButtonId="updateZButton"/>
        </div>
    </section>
  );
}
