import React, { useState } from "react";
import './InputWithSubmit.css';

export function InputWithButton({labelname, labeltext, onSubmit, submitButtonId}){
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    }
  
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log("Submitting message:", inputValue);
      onSubmit(inputValue);
      setInputValue('');
    }
  
    return (
      <form onSubmit={handleSubmit}>
        <label htmlFor={labelname}>{labeltext}</label>
        <input
          type="text"
          id={labelname}
          value={inputValue}
          onChange={handleInputChange}
        />
        <button data-testid={submitButtonId} type="submit">Submit</button>
      </form>
    );
  }
