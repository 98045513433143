import React, { useEffect, useState } from 'react';
import { SensorCard } from './Cards/SensorCard';
// import { InputWithButton } from './inputs/InputWithSubmit';
import './Roles.css';
import { SensorReader } from './SensorReader';
//import axios from 'axios';
//import HttpCall from "./components/HttpCall";
import { InputWithButton } from './inputs/InputWithSubmit';
const ws = new WebSocket(process.env.REACT_APP_API_URL);
// ws.addEventListener('message', (data) => {
//   //console.log(data);
// });


// const URL = process.env.REACT_APP_API_URL

export function Publisher({ title, needsToFetch, setNeedsToFetch}) {

    const [gyroX, setGyroX] = useState(0)
    const [gyroY, setGyroY] = useState(0)
    const [gyroZ, setGyroZ] = useState(0)
    
    const [accX, setAccX] = useState(0)
    const [accY, setAccY] = useState(0)
    const [accZ, setAccZ] = useState(0)

    const [oriX, setOriX] = useState(0)
    const [oriY, setOriY] = useState(0)
    const [oriZ, setOriZ] = useState(0)

    const [offX, setOffX] = useState(0)
    const [offY, setOffY] = useState(0)
    const [offZ, setOffZ] = useState(0)

  const [inputValue, setInputValue] = useState('')

  const handleClick = (inputVal) => {
      // ws.addEventListener('open', () => {
      //   ws.send(JSON.stringify({
      //     channel: inputValue,
      //     message: "Hello, channel!"
      //   }));
      // });



        if (inputValue){
          ws.send(JSON.stringify({
            channel: inputValue,
            event: 'unsubscribe',
            data: 'I want to unsubscribe',
          }));
        }
        setInputValue(inputVal)
      

   
  };

  useEffect(() => {
    // add event listener to close WebSocket connection when the window is closed
    window.addEventListener("beforeunload", () => {
      ws.close();
    });
  
    return () => {
      // remove event listener when component is unmounted
      window.removeEventListener("beforeunload", () => {});
    };
  }, []);

  const handleSYNC = (inputVal) => {
    setOffX(oriX);
    setOffY(oriY);
    setOffZ(oriZ);
  };

  const submitMessage = (message) => {
    if (inputValue){
      ws.send(JSON.stringify({
        channel: inputValue,
        event: "publish",
        message: message
      }));
    }
    
  };

  useEffect(() => {
    submitMessage(JSON.stringify({
      "x": oriX,//(oriX-offX)/180*Math.PI,
      "y": oriY,//(oriY-offY)/180*Math.PI,
      "z": oriZ,//(oriZ-offZ)/180*Math.PI
      "xOff": offX,//(oriX-offX)/180*Math.PI,
      "yOff": offY,//(oriY-offY)/180*Math.PI,
      "zOff": offZ//(oriZ-offZ)/180*Math.PI
    }))
  }, [oriX, oriY, oriZ]);
      
  
  return (
    <section>
        <div className='sensors'>
            
           <InputWithButton labelname={"channelpub"} labeltext={"Publish to Channel"} onSubmit={handleClick} submitButtonId="publishToButton"/>
          <button className='syncButton' onClick={handleSYNC}>SYNC</button>
            {/* <p>Subscribed to channel: {channel}</p> */}
            
            <SensorCard title="Gyro" x={gyroX} y={gyroY} z={gyroZ}/>
            <SensorCard title="Acc" x={accX} y={accY} z={accZ}/>
            <SensorCard title="Ori" x={oriX-offX} y={oriY-offY} z={oriZ-offZ}/>
            <InputWithButton labelname={"channelSend"} labeltext={"Send this message to channel"} onSubmit={submitMessage} submitButtonId="sendButton"/>
            
            <SensorReader setGyroX={setGyroX} setGyroY={setGyroY} setGyroZ={setGyroZ} setAccX={setAccX} setAccY={setAccY} setAccZ={setAccZ} setOriX={setOriX} setOriY={setOriY} setOriZ={setOriZ}/>
            {/* <button onClick={sendMessageButton}>send message</button> */}
        </div>
    </section>
  );
}
