import * as THREE from 'three';
import React, { Component } from 'react';
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three-stdlib';
import './Subscriber.css';


class ThreeCanvas extends Component {
  constructor(props, rotationX, rotationY, rotationZ, offsetX, offsetY, offsetZ, isPhone) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
   
    const canvas = this.canvasRef.current;
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio*2); // increase pixel density
    renderer.setClearColor(0x000000, 0); // set the clear color to transparent

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, canvas.width / canvas.height, 0.1, 1000);
    camera.position.z = 2;


    const tloader = new THREE.TextureLoader();
    const texture = tloader.load('/images/pano1.jpeg')
    const geometry = new THREE.SphereGeometry(10, 32, 32);
    geometry.scale(-1,1,1)
    const material = new THREE.MeshBasicMaterial({
      map: texture
    });
    const sphere = new THREE.Mesh(geometry,material);
    scene.add(sphere);
    // const geometry = new THREE.BoxGeometry(1, 2, 0.2);
    // const material = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
    // const cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);
    
    let cube;
    const loader = new GLTFLoader()
    loader.load(
      // resource URL
      '/models/phone.gltf',
      // called when the resource is loaded
      function ( gltf ) {
        cube = gltf.scene; 
     
        // gltf.scene.rotation.y = -0.5 * Math.PI; 
       scene.add( gltf.scene );
      },
      // called while loading is progressing
      function ( xhr ) {
        console.log( "phone model: " + ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
      },
      // called when loading has errors
      function ( error ) {
        console.log( 'An error happened' );
      }
    );

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 3);
    pointLight.position.set(5, 5, 5);
    camera.add(pointLight);
    const pointLight2 = new THREE.PointLight(0xffffff, 8);
    pointLight2.position.set(-5, 5, -5);
    camera.add(pointLight2);
    const pointLight3 = new THREE.PointLight(0xffffff, 2);
    pointLight3.position.set(-10, -1, 5);
    camera.add(pointLight3);
    scene.add(camera);
 
    const animate = () => {
      
      requestAnimationFrame(animate);
      
      let { rotationX, rotationY, rotationZ, offsetX, offsetY, offsetZ, isPhone} = this.props;
      if (cube && isPhone){
        if (sphere){
        sphere.visible = false; }
        cube.visible = true; 
        camera.rotation.x = 0;
        camera.rotation.y = 0;
        camera.rotation.z = 0;
        camera.position.z = 2;
        let euler = new THREE.Euler(rotationX/180*Math.PI, rotationY/180*Math.PI, rotationZ/180*Math.PI, 'ZXY');
        let offX = 0; 
        let offY = 0;
        let offZ = 0; 

        if (offsetX !== 0 && offsetY !== 0 && offsetZ !== 0){
          let eulerOffset = new THREE.Euler(offsetX/180*Math.PI, offsetY/180*Math.PI, offsetZ/180*Math.PI, 'ZXY')
          cube.setRotationFromEuler(eulerOffset);
          offX = cube.rotation.x
          offY = cube.rotation.y
          offZ = cube.rotation.z
        }

        cube.setRotationFromEuler(euler);
        if (rotationX !== 0 && rotationY !== 0 && rotationZ !== 0){
          cube.rotation.x -= 0.5*Math.PI; 
        }
        cube.rotation.x -= offX
        cube.rotation.y -= offY
        cube.rotation.z -= offZ
         
      }
      else if (sphere && !isPhone){
  
        sphere.visible = true; 
        if (cube) {
          cube.visible = false;
        }
        camera.position.z = 0; 
        let euler = new THREE.Euler(rotationX/180*Math.PI, rotationY/180*Math.PI, rotationZ/180*Math.PI, 'ZXY');
        let offX = 0; 
        let offY = 0;
        let offZ = 0; 

        if (offsetX !== 0 && offsetY !== 0 && offsetZ !== 0){
          let eulerOffset = new THREE.Euler(offsetX/180*Math.PI, offsetY/180*Math.PI, offsetZ/180*Math.PI, 'ZXY')
          camera.setRotationFromEuler(eulerOffset);
          offX = camera.rotation.x
          offY = camera.rotation.y
          offZ = camera.rotation.z
        }

        camera.setRotationFromEuler(euler);
        if (rotationX !== 0 && rotationY !== 0 && rotationZ !== 0){
          camera.rotation.x -= 0.5*Math.PI; 
        }
        camera.rotation.x -= offX
        camera.rotation.y -= offY
        camera.rotation.z -= offZ
      }

      renderer.render(scene, camera);
    }

    animate();
  }

  render() {
    return (
      <canvas className='threeCanvas' ref={this.canvasRef} />
    );
  }
}

export { ThreeCanvas };
