
import './App.css';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Publisher } from './components/Publisher';
import { NotFound } from './components/NotFound';
import { RoleSelect } from './components/SelectRole';
import { Subscriber } from './components/Subscriber';

function App() {
   return (
    <div className="App">
      <header className="App-header">
      
        <BrowserRouter>
          <Routes>
            <Route index element={<RoleSelect />} />
            <Route path="/publisher/" element={<Publisher />} />
            <Route path="/subscriber/" element={<Subscriber />} />
            <Route path="*" element={<NotFound />} />
            {/* </Route> */}
          </Routes>
        
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
