import  { useEffect } from 'react';
// import * as THREE from 'three';
// import { quat, vec3 } from 'gl-matrix';

export function SensorReader({
  setGyroX,
  setGyroY,
  setGyroZ,
  setAccX,
  setAccY,
  setAccZ,
  setOriX,
  setOriY,
  setOriZ
}) {

  useEffect(() => {

    if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              window.addEventListener('devicemotion', handleMotionEvent);
            }
          })
          .catch(console.error);
    }
    else {
      // DeviceMotionEvent and DeviceOrientationEvent are not supported
      console.error('Device motion events are not supported');
    }
    if (typeof DeviceOrientationEvent.requestPermission === 'function'){
      DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              if ('ondeviceorientationabsolute' in window) {
                window.addEventListener('deviceorientationabsolute', handleOrientationEvent);
              } else {
                window.addEventListener('deviceorientation', handleOrientationEvent);
              }
            }
          })
          .catch(console.error);
    }
        
    else {
      // DeviceMotionEvent and DeviceOrientationEvent are not supported
      console.error('Device orientation events are not supported');
    }
    
    return () => {
      window.removeEventListener('devicemotion', handleMotionEvent);
      if ('ondeviceorientationabsolute' in window) {
        window.removeEventListener('deviceorientationabsolute', handleOrientationEvent);
        setAccX(1)
      } else {
        window.removeEventListener('deviceorientation', handleOrientationEvent);
        setAccX(-1)
      }
      //window.removeEventListener('deviceorientation', handleOrientationEvent);
    };
    }, );

  const handleMotionEvent = (event) => {
    //setAccX(event.acceleration.x);
    setAccY(event.acceleration.y);
    setAccZ(event.acceleration.z);
    setGyroX(event.rotationRate.beta);
    setGyroY(event.rotationRate.gamma);
    setGyroZ(event.rotationRate.alpha);
  };

  
  const handleOrientationEvent = (event) => {
    setOriX(event.beta)
    setOriY(event.gamma);
    setOriZ(event.alpha); 
  };

  return null;
}