import './Cards.css';
import React from 'react'

export function SensorCard({title, x, y, z}) {
    return(
        <div className="sensor">
            <h2>{title}</h2>
            <span>
                X=<p id="oriX">{x}</p>
            </span>
            <span>
                Y=<p id="oriY">{y}</p>
            </span>
            <span>
                Z=<p id="oriZ">{z}</p>
            </span>
            {/* <p id="oriX">{'X='+ x}</p>
            <p id="oriY">{'Y='+ y}</p>
            <p id="oriZ">{'Z='+ z}</p> */}
        </div>
    )
}